import { useLocation } from "react-router-dom";
import OrderDetailsLeftSection from "./OrderDetailsSections/leftSection";
import OrderDetailsRightSection from "./OrderDetailsSections/rightSections";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelOrder, completeOrder, getAssignHistory, getInvoice, getOrderById } from "../../../Components/Redux/Slice/Orders/orders";
import BreadCrumb from "../../../Components/BreadCrumb/breadCrumb";
import DataTable from "react-data-table-component";
import OrderTicket from "./orderTicket";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, Menu, MenuItem, Modal, Radio, RadioGroup, Select } from "@mui/material";
import { FaCheck, FaRegTimesCircle } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { getOrderTickets, getTicketsTypes, sendOrderTickets } from "../../../Components/Redux/Slice/Tickets/tickets";
import { dateFormate } from "../../../Components/Common/dateFormate";
import { TableStyles } from "../../../Components/Common/tableStyle";
import RefundCustomer from "./OrderDetailsSections/refundCustomerModal";
import Overlay from '../../../Components/Common/overlay'
import { IoCloseCircleOutline } from "react-icons/io5";
import receipt from '../../../Assets/imgs/receipt-bg.svg'
import { useTranslation } from "react-i18next";
import { LuArrowBigLeft, LuArrowBigRight } from "react-icons/lu";

const OrderDetails = () => {
    const {t,i18n} = useTranslation()
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,reset} = form
    const {errors} = formState

    const location = useLocation()
    let id = location?.state?.id
    const dispatch = useDispatch()
    const {singleOrder} = useSelector(state=>state.orders)
    const {invoice} = useSelector(state=>state.orders)
    const {ticketType} = useSelector(state=>state.tickets)
    const {auth} = useSelector(state=>state)
    const ticketSelector = useSelector(state=>state.tickets)
    const {refund} = useSelector(state=>state)
    const {loading} = useSelector(state=>state.orders)

    // console.log(invoice?.data);

    useEffect(() => {
        dispatch(getOrderById(id))
        dispatch(getTicketsTypes())
        dispatch(getOrderTickets(id))
        dispatch(getInvoice(id))
        dispatch(getAssignHistory(id))
    }, [dispatch,id])

    const paths = [
        {path:'/dashboard/orders',name:'Orders',nameAr:'الطلبات'},
        {path:'',name:'order details', nameAr:'تفاصيل الطلب'},
    ]
    

    const style = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        minWidth : '550px',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        px: 2,
        py: 3,
        borderRadius : 5,
    };

    const [open, setOpen] = useState(false);
    
    const handleOpen = (e) => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    // textarea
    const [textareaCounter, settextareaCounter] = useState(0);

    useEffect(() => {
        // console.log(textareaCounter);
    }, [textareaCounter])

    //ticket type

    const [tickets, settickets] = useState([]);

    const handleTypeChange = (event) => {
        let ticketTypes = event.target.value 
        // settype({id:val});
        settickets(ticketTypes);
        setValue('type',ticketTypes)
    };

    const submitHandler = async (values)=>{        
        // console.log(values);
        dispatch(sendOrderTickets({id,values})).then( (e)=>{
            if(e.payload.message === 'SUCCESS'){
                settextareaCounter(0)
                handleClose()
                reset()
                settickets()
                dispatch(getOrderTickets(id))
            }
        } )
        //after dispatching the api make sure to settextareacounter to 0 and reset the form from the old values
    }


    // const {accounts} = useSelector(state=>state)

    const [transPage,settransPage] = useState(1)
    const [transSize,settransSize] = useState(10)

    const transColumns = [
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم مالك الحساب' : 'Account Holder Name'}`,
            selector: row => row.accountHolderName,
            sortable: false,
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'نوع الحساب' : 'Account Holder Type'}`,
            selector: row => row.accountHolderType,
            sortable: false,
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'الكمية' : 'Amount'}`,
            cell: row =><><span className={`${row?.outward ? 'text-mainRed' : 'text-mainGreen'}`}>{row.transactionAmount}</span>&nbsp; <span> {t('common.sar')}</span></>,
            sortable: false,
        },
        
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'تم انشاءه في' : 'Created at'}`,
            selector: row => dateFormate(row.createdAt),
            sortable: false,
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'الحاله' : 'status'}`,
            selector: row => <span className={`block px-2 py-[6px] rounded-md bg-opacity-10 min-w-[130px] font-semibold text-center capitalize ${row?.transactionStatus === 'COMPLETED' ? 'text-mainGreen bg-mainGreen' : row?.transactionStatus === 'PENDING' ? 'text-amber-400 bg-amber-400' : row?.transactionStatus === 'CANCELLED' ? 'text-mainRed bg-mainRed' : row?.transactionStatus === 'ON_HOLD' ? 'text-mainYellow bg-mainYellow' : null}`}>
                        {
                            row?.transactionStatus === 'COMPLETED' ? t('common.completed') :
                            row?.transactionStatus === 'PENDING' ? t('common.pending') :
                            row?.transactionStatus === 'CANCELLED' ? t('common.cancelled') :
                            row?.transactionStatus === 'ON_HOLD' ? t('common.onHold') : null
                        }
                    </span>,
            sortable: false,
        },
        // {
        //     name: 'Order ID',
        //     cell: row => <Button><Link to='/dashboard/orders/orderdetails' state={{id:row?.orderId}}>{row?.orderId}</Link></Button> ,
        //     sortable: false,
        // },
        // {
        //     name: 'Actions',
        //     allowOverflow: true,
        //     button : true,
        //     cell: ActionCell
                
        // },
    ];
    
    const transData = singleOrder?.data?.transactions

    const handleTransPageChange = page => {
        settransPage(page);
    };
    const handleTransactionRowChange = (perPage) => {
        settransSize(perPage);
    };

    useEffect(() => {
        // console.log(size)
        // dispatch(getTransaction({id,transPage,transSize}))
    }, [dispatch, transPage,transSize])


    // refund modal
    const [openRefund, setOpenRefund] = useState(false);
    
    const handleOpenRefund = () => {
        setOpenRefund(true)
    };
    const handleCloseRefund = () => setOpenRefund(false);

    // modal 

    const [openModal, setopenModal] = useState(false)
    const handelOpen = ()=>{
        setopenModal(true)
    }
    const handelClose = ()=>{
        setopenModal(false)
    }


    // order status select

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenSelect = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [orderStatus, setorderStatus] = useState('Status');
    const handleChange = (event) => {
        setorderStatus(event.target.value);
        // handleCloseSelect();
    };
    
    const handleCloseSelect = () => {
        setAnchorEl(null);
    };

    const orderStatusList = [
        {id:1, name:t('order_details.picked_up')},
        {id:2, name:t('order_details.delivered')},
    ]

    const completeOrderHanlder = ()=>{
        dispatch(completeOrder(id))
    }

    const cancelOrderHanlder = ()=>{
        dispatch(cancelOrder(id)).then( (e)=>{
            if(e?.payload?.message === "SUCCESS"){
                dispatch(getOrderById(id))
            }

        } )
    }
    return ( 
        <article className="order-details-wrapper pb-6">
            <div className="order-details-title-wrapper flex gap-x-5 items-center text-[#464E5F] font-bold text-xl md:text-2xl lg:text-3xl capitalize mb-5">
                <h1>{t('order_details.title')} #{id}</h1>

                <div>
                    {/* <Button variant="outlined" className={`border bg-opacity-20 ${orderStatus === 'picked up' ? 'bg-mainYellow text-mainYellow border-[#f8d859]' : orderStatus === 'delivered' ? 'bg-mainGreen text-mainGreen border-[#47c991]' : 'bg-mainColor text-mainColor border-mainColor'}`} onClick={handleOpenSelect}>
                        {orderStatus}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseSelect}
                        className="[&>div>ul]:px-2"
                    >
                        <p className="capitalize mb-3 pb-1 border-b font-semibold font-Ubuntu">Order Status</p>
                        {orderStatusList?.map( (status,index)=>(
                            <MenuItem key={status?.id} className={`py-1 rounded-full capitalize mb-2 bg-opacity-20 ${status?.name === 'picked up' ? 'bg-mainYellow text-mainYellow' : 'bg-mainGreen text-mainGreen'}`}>
                                <FormControlLabel sx={{'& .MuiFormControlLabel-root' : {p:2}}} value={status?.name} control={<Radio className="[&>svg]:text-mainColor [&>svg]:text-xl" checkedIcon={<RiCheckboxCircleFill />} icon={<RiCheckboxBlankCircleLine />} />} label={status?.name} checked={orderStatus === status?.name} onChange={handleChange} />
                            </MenuItem>
                        ) )}
                    </Menu> */}
                    {/* {console.log(singleOrder?.data?.orderStatus)} */}
                    {(singleOrder?.data?.orderStatus === 'CANCELLED' || singleOrder?.data?.orderStatus === 'REJECTED' || singleOrder?.data?.orderStatus === 'DELIVERED' || singleOrder?.data?.orderStatus === 'ABANDONED' || singleOrder?.data?.orderStatus === 'PICKED_UP') ? null : <Button onClick={completeOrderHanlder} className="bg-mainColor text-white capitalize font-semibold">{t('order_details.complete_order_btn')}</Button>}
                </div>

            </div>

            <div className="flex items-center justify-between -mt-4">
                <div>
                    <BreadCrumb paths={paths} seperator='/' />
                </div>

                <div className="flex gap-3 items-center mb-5 flex-row-reverse">

                    <div>
                        <Button onClick={handleOpenRefund} className="bg-mainColor text-white capitalize px-4 rounded-sm">{t('order_details.refund_btn')}</Button>
                    </div>

                    
                    {auth?.role === 'CUSTOMER_SERVICE' ? 
                        <div>
                            <Button onClick={handleOpen} className="bg-mainColor text-white capitalize px-4 rounded-sm">{t('order_details.create_ticket_btn')}</Button>
                        </div>
                        : null
                    }

                    <div className="[&>button]:border-[1px]">

                        <Button onClick={handelOpen} sx={{ borderBottom: 1,pb:'1px' }}  className="capitalize text-mainGreen border-b border-mainGreen">{t('order_details.view_receipt_btn')}</Button>
                    </div>

                    <div>
                        <Button title={!invoice?.data ? t('order_details.no_invoice') : null} disabled={!invoice?.data || loading} className="disabled:bg-opacity-25 disabled:bg-gray-800 disabled:text-gray-400 disabled:border-none disabled:cursor-not-allowed disabled:pointer-events-auto  capitalize text-mainColor font-semibold font-Ubuntu bg-transparent hover:bg-mainColor hover:text-white border-solid border-[2px] border-mainColor">
                            <a href={invoice?.data?.directLink} target="_blank" download rel="noreferrer">{t('order_details.download_invoice')}</a>
                        </Button>
                    </div>

                    <div>
                        {(singleOrder?.data?.orderStatus === 'REJECTED' || singleOrder?.data?.orderStatus === 'DELIVERED' || singleOrder?.data?.orderStatus === 'ABANDONED' || singleOrder?.data?.orderStatus === 'PICKED_UP') ? null : <Button onClick={cancelOrderHanlder} disabled={singleOrder?.data?.orderStatus === 'CANCELLED'} className="disabled:bg-opacity-30 disabled:cursor-not-allowed disabled:pointer-events-auto disabled:bg-gray-500 disabled:hover:bg-gray-500 disabled:text-gray-800 disabled:hover:text-gray-800 disabled:border-none capitalize text-mainRed font-semibold font-Ubuntu bg-transparent hover:bg-mainRed hover:text-white border-solid border-[2px] border-mainRed">{singleOrder?.data?.orderStatus === 'CANCELLED' ? t('common.order_cancelled') : t('order_details.cancel_order')}</Button>}
                    </div>
                </div>
                
            </div>

            <div className="flex flex-wrap md:flex-nowrap justify-between gap-y-2 md:gap-x-3 lg:gap-x-5">
                <section className="order-details-lefft-section-wrapper basis-full lg:basis-[33%]">
                    <OrderDetailsLeftSection data={singleOrder?.data} t={t} />
                </section>

                <section className="order-details-right-section-wrapper basis-full lg:basis-[67%] text-left">
                    <OrderDetailsRightSection data={singleOrder?.data} t={t} />
                </section>
            </div>

            <div className="toruble-ticket-wrapper bg-white rounded-md p-3 capitalize mt-4">
                <div className="ticket-title-wrapper mb-3">
                    <h2 className="text-xl text-mainColor font-bold mb-2">{t('order_details.title')} :#{id}</h2>
                    <h3 className="font-semibold text-lg text-[#616161b3]">{t('order_details.trouble_ticket')}</h3>
                </div>

                <div className="order-ticket-table-wrapper">
                    <OrderTicket tickets = {ticketSelector} orderID ={id} t={t} />
                </div>
            </div>

            <div className="mt-6">
                <h2 className="text-lg text-mainColor font-bold mb-2 capitalize">{t('order_details.order_transaction.title')}</h2>
                <div className="relative bg-white rounded-md"> 
                    <DataTable
                        columns={transColumns}
                        data={transData}
                        // pagination
                        // paginationPerPage = {transSize}
                        // paginationRowsPerPageOptions = {[10,50,100]}
                        // paginationServer
                        // paginationTotalRows={accounts?.transactions?.meta?.total}
                        onChangePage={handleTransPageChange}
                        onChangeRowsPerPage={handleTransactionRowChange}
                        customStyles={TableStyles}
                        highlightOnHover
                        paginationComponentOptions={
                                {
                                    rowsPerPageText : t('common.paginationRowText'),
                                    rangeSeparatorText : t('common.of')
                                }
                            }
                        // sortServer
                        // onSort={handleRemoteSort}
                    />

                {/* {accounts?.transLoading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null} */}

                </div>
            </div>

            {refund?.loading || loading ? <Overlay /> : null}

            <>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                        <div className="flex justify-between items-center border-b pb-2 mb-3">
                            <h1 className="text-xl text-[#333] capitalize font-semibold">{t('order_details.ticket_modal.form.title')}</h1>

                            <div>
                                <FaRegTimesCircle onClick={handleClose} className="text-mainRed text-xl cursor-pointer" />
                            </div>
                        </div>
                        <form noValidate onSubmit={handleSubmit(submitHandler)}>
                            <div className=" [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                <div className="mb-4 [&>div>div>div>span]:capitalize">
                                    <label htmlFor="Type">{t('order_details.ticket_modal.form.type')}</label>
                                    <FormControl sx={{ minWidth: 120,width:'100%',backgroundColor:'#f3f6f9',border:0,m:0,borderRadius:'6px','& fieldset': { borderColor: 'primary.main',border:'none', } }} size="small">
                                        {/* <InputLabel id="select-type" className='text-mainColor font-semibold'>Type</InputLabel> */}
                                        <Select
                                            labelId="select-type"
                                            id="demo-select-type"
                                            value={tickets}
                                            // label="Type"
                                            onChange={handleTypeChange}
                                        >
                                            <MenuItem value= "" className='text-[#333] font-semibold'>All</MenuItem>
                                            {ticketType?.data?.map( item=>(
                                                <MenuItem key={item} value={item} className='capitalize [&>span]:capitalize text-[#333] font-semibold'><span>{item?.toLowerCase()?.replace(/_/g, " ")}</span></MenuItem>
                                            ) )}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="w-full">
                                    <label>{t('order_details.ticket_modal.form.description')}</label>

                                    <div className="relative">
                                        <textarea
                                            onKeyUp={(e)=>settextareaCounter(e.target.value.length)}
                                            id="description"
                                            className="w-full  h-auto min-h-[90px] resize-none rounded-md bg-[#f3f6f9] focus:outline-slate-400 text-mainColor p-2"
                                            {...register('description',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                minLength : {
                                                    value : 10,
                                                    message : t('common.min_length',{length : '10'})
                                                },
                                                maxLength : {
                                                    value : 500,
                                                    message : t('common.max_length',{length : '500'})
                                                },
                                                // pattern: {
                                                //     value: /^[a-zA-Z0-9&\s]+$/,
                                                //     message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                                // },
                                            })}

                                            minLength={10}
                                            maxLength={500}
                                        >
                                        </textarea>
                                        <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{500 - textareaCounter}</p>
                                    </div>
                                    
                                    {errors?.description?.message ? <p className="capitalize text-sm text-danger">{errors?.description?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('order_details.ticket_modal.form.desc_en_helper')}</p>}
                                </div>
                                
                                <div className={`flex justify-end item-center ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} gap-x-4 mt-6 mb-0 capitalize text-sm [&>button]:text-white [&>button]:capitalize [&>button]:rounded-lg [&>button]:px-4`}>
                                    <Button className="bg-gray-400" onClick={handleClose}>{t('common.cancel_btn')}</Button>
                                    <Button className="bg-mainColor disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed" type="submit" disabled={ticketSelector?.loading}>{t('common.submit')}</Button>
                                </div>
                            </div>
                        </form>
                    </Box>
                </Modal>
            </>

            <>
                <RefundCustomer open={openRefund} close={handleCloseRefund} id={id} t={t} />
            </>
            
            <>
                <Modal
                    open={openModal}
                    onClose={handelClose}
                    aria-labelledby="receipt-title"
                    aria-describedby="receipt-modal-description"
                >

                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                        <div className="w-[430px] h-[640px] m-auto relative overflow-hidden">
                            <div className="w-full h-full absolute z-1 top-0 bottom-0 left-0 right-0">
                                <img src={receipt} alt="receipt-paper" className="object-cover h-full" />
                            </div>

                            <div className="absolute top-2 right-2 z-20  w-[96%] overflow-hidden">
                                <div className="pb-3 border-b-[2px] border-dashed w-full text-right">
                                    <span className="text-3xl text-mainRed z-20 cursor-pointer w-fit block ml-auto" onClick={handelClose}> <IoCloseCircleOutline /> </span>
                                </div>
                                <div className="receipt-body-wrapper h-[300px] overflow-y-auto p-2 [&>div>p]:mb-2 pb-3 border-b-[2px] border-dashed">
                                    {singleOrder?.data?.orderItems?.map( (item,index,array)=>(
                                        <div key={index} className={`capitalize mb-2 pb-2 ${index !== array.length - 1 ? 'border-b' : ''}`}>
                                            <p className="item-name text-[#333] flex items-center justify-between">
                                                <span className="font-semibold">{localStorage.getItem('lang') === 'ar' ? item?.itemNameAr : item?.itemName}</span>
                                                <span>{t('common.sar')} {item?.itemPrice}</span>
                                            </p>
                                            {item?.options && item?.options?.length !== 0 ? <p className="text-sm text-mainColor capitalize font-semibold">{t('deals.form.options.title')}</p> : null}
                                            { item?.options?.map(item=>(
                                                <>
                                                    <p className="options text-[#999] text-sm flex items-center justify-between">
                                                        <span>
                                                            <span>{item?.quantity} X </span>
                                                            <span className="font-semibold text-[#727272]">{localStorage.getItem('lang') === 'ar' ? item?.nameAr : item?.name}</span>
                                                            <span className="[&>svg]:inline-block mx-1">{localStorage.getItem('lang') === 'ar' ? <LuArrowBigLeft /> : <LuArrowBigRight /> }</span>
                                                            <span>{item?.price}</span>
                                                        </span>
                                                        <span>{t('common.sar')} {parseFloat(item?.price) * parseFloat(item?.quantity)}</span>
                                                    </p> 
                                                </>
                                            )) 
                                            }
                                            {item?.extras && item?.extras?.length !== 0 ? <p className="text-sm text-mainColor capitalize font-semibold">{t('deals.form.extras.title')}</p> : null}

                                            {item?.extras?.map( (ext,index2)=>(
                                                <p key={index} className="extras text-[#999] text-sm flex items-center justify-between">
                                                    <div>
                                                        <span>{ext?.quantity} X </span>
                                                        <span>{localStorage.getItem('lang') === 'ar' ? ext?.nameAr : ext?.name}</span>
                                                    </div>
                                                    <span>{t('common.sar')} {ext?.price}</span>
                                                </p>
                                            ) )}

                                            <div className="mt-6 flex items-center justify-between text-sm">
                                                <p className="item-quantity text-[#999]">{item?.itemQuantity} x</p>
                                                {/* <p>{t('common.sar')} {parseFloat(item?.itemPrice)?.toFixed(2)}</p> */}
                                                <p>{t('common.sar')} {((parseFloat(item?.itemPrice) + (item?.options ? item?.options?.reduce((acc, item) => acc + (item.price * item.quantity), 0) : 0) + (item?.extras ? item?.extras?.reduce((acc, item)=>{return parseFloat(acc) + parseFloat(item?.price)},0) : 0)) * parseFloat(item?.itemQuantity) ) }</p>
                                            </div>
                                        </div>
                                    ) )}

                                </div>

                                <div className="receipt-price-wrapper mt-3">

                                    <div className="sub-total-wrapper [&>div]:mb-5 capitalize">
                                        <div className="flex items-center justify-between">
                                            <p className="font-semibold">{t('order_details.receipt.sub_total')}</p>
                                            <p className="font-semibold">{t('common.sar')} {parseFloat(singleOrder?.data?.originalPrice)?.toFixed(2)}</p>
                                        </div>

                                        <div className="flex items-center justify-between pb-2 border-b text-sm">
                                            <p className="text-[#999]">{t('order_details.receipt.discount_amount')}</p>
                                            <p className="font-semibold">{t('common.sar')} {singleOrder?.data?.discountAmount ? parseFloat(singleOrder?.data?.discountAmount)?.toFixed(2) : 0}</p>
                                        </div>

                                        <div className="flex items-center justify-between text-sm">
                                            <p className="text-[#999]">{t('order_details.receipt.delivery_charges')}</p>
                                            <p className="font-semibold">{t('common.sar')} {parseFloat(singleOrder?.data?.deliveryCharges)?.toFixed(2)}</p>
                                        </div>

                                        <div className="flex items-center justify-between pb-2 border-b text-sm">
                                            <p className="text-[#999]">{t('order_details.receipt.wallet_balance_used')}</p>
                                            <p className="font-semibold">{t('common.sar')} {parseFloat(singleOrder?.data?.walletBalanceUsed)?.toFixed(2)}</p>
                                        </div>
                                    </div>

                                    <div className="total-amount-wrapper capitalize text-mainColor font-semibold flex items-center justify-between">
                                        <span>{t('order_details.receipt.total_amount')}</span>

                                        <span>{t('common.sar')} {parseFloat(singleOrder?.data?.finalPrice)?.toFixed(2)}</span>
                                    </div>
                                </div>                                
                            </div>

                            
                        </div>

                    </div>
                </Modal>
            </>
        </article>
    );
}

export default OrderDetails;