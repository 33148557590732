import { GiReceiveMoney } from "react-icons/gi"; 
import DataTable from "react-data-table-component";
import { clearCourierState, getCouriers } from "../../../Components/Redux/Slice/Couriers/couriersSlice";
import { useCallback, useEffect, useState } from "react";
import { dateOnlyFormate } from "../../../Components/Common/dateFormate";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, IconButton, InputAdornment, InputLabel, ListItemButton, Menu, MenuItem, OutlinedInput, Select } from "@mui/material";
import { CiSearch } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import ColumnSelection from "../../../Components/Common/columnSelection";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import NoDataEmoji from "../../../Components/Common/nodataEmoje";
import { tabHandler } from "../../../Components/Redux/Slice/common/tabSlice";
import { TableStyles } from "../../../Components/Common/tableStyle";
import { AiFillStar } from "react-icons/ai";
import PayoutModal from "./changePayoutModal";

function ActionCell({ data, t }) {
    // const [openModal, setopenModal] = useState(false)
    // const handelOpen = ()=>setopenModal(true)
    // const handelClose = ()=>setopenModal(false)
    // console.log(data);

    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let id = data?.id

    const [openPayout, setopenPayout] = useState(false)

    const handleOpenPayout = ()=>{
        setopenPayout(true)
    }

    const handleClosePayout = ()=>{
        setopenPayout(false)
    }
    // console.log(data); 

    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:0 } }}
                className='shadow-md p-0'
            >
                <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    {/* <MenuItem className='text-mainGreen hover:bg-[#5cac7d42]'><BsTelephone /> +9225621123</MenuItem> */}
                    <MenuItem className='text-[#545151c2] hover:bg-[#442b7e42]'><Link to='details' onClick={()=>dispatch(clearCourierState())} state={{id:data?.id,type:'status',data:data}} className={`w-full flex items-center gap-x-3 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}><FaEye className='text-xl' /> {localStorage.getItem('lang') === 'ar' ? 'عرض التفاصيل' : 'View Details'}</Link></MenuItem>
                    <MenuItem className='text-[#6993FF] hover:bg-[#6994ff42]' onClick={handleOpenPayout}><GiReceiveMoney />{t('couriers.courier_details.editPayoutBtn')} </MenuItem>
                    {/* <MenuItem className='text-mainYellow hover:bg-[#d9ad5642]'><FaBarcode />restaurant menu</MenuItem> */}
                    {/* <MenuItem className='text-[#3699FF] hover:bg-[#369bff42]'><Link to='/dashboard/restaurant/orders' state={{id:data?.id, name:data?.name}} className='w-full flex items-center gap-x-3' ><RiMoneyDollarCircleLine className='text-2xl' />completed orders</Link></MenuItem> */}
                    {/* <MenuItem className='text-mainRed hover:bg-[#c6345b42]'><FaStoreAltSlash />deactivate</MenuItem> */}
                </div>
            </Menu>

            {/* <EditrestaurantModal open={openModal} close={handelClose} data={data} img={kfc} /> */}
            <PayoutModal open={openPayout} close={handleClosePayout} type='tableAll' id={id} data={data} />
        </div>
    );
}

const AllCouries = ({t}) => {
    const dispatch = useDispatch()
    const couriers = useSelector(state=>state?.couriers)
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)
    const [searchValue, setsearchValue] = useState('')
    const [orderType, setorderType] = useState();
    const [payoutStatus, setpayoutStatus] = useState(null)

    const navigate = useNavigate()

    const {currentPage} = useSelector((state) => state?.resetPagination);
    const {toggle} = useSelector((state) => state?.resetPagination);

    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')

    // console.log(orderType);
    //data table section

    useEffect(() => {
        dispatch(getCouriers({page,size}))
        dispatch(tabHandler({tabs:'ALL',index:0}))
    }, [dispatch])

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    const columns = [
        {
        id: 'name',
        name: `${localStorage.getItem('lang') === 'ar' ? 'الاسم' : 'Name'}`,
        cell: row => <div className="text-left w-full flex self-start justify-start" data-tag="allowRowEvents">{row?.firstName +" " + row?.lastName}</div>,
        sortable: true,
        },
        {
        id: 'email',
        name: `${localStorage.getItem('lang') === 'ar' ? 'الايميل' : 'Email'}`,
        selector: row => row?.email,
        sortable: true,
        minWidth : '180px'
        },
        {
        id: 'phone',
        name: `${localStorage.getItem('lang') === 'ar' ? 'الهاتف' : 'Phone'}`,
        selector: row => row?.mobileNumber,
        sortable: false,
        minWidth : '180px'
        },
        {
        id: 'gender',
        name: `${localStorage.getItem('lang') === 'ar' ? 'الجنس' : 'Gender'}`,
        cell: row => <span data-tag="allowRowEvents" className="capitalize">{row?.gender === 'male' ? t('common.male') : t('common.female') }</span>,
        sortable: false,
        },
        {
        id: 'courierType',
        name: `${localStorage.getItem('lang') === 'ar' ? 'نوع السائق' : 'Courier Type'}`,
        cell: row => <span data-tag="allowRowEvents" className="capitalize">{row?.isFreelancer ? t('couriers.freelancer') : localStorage.getItem('lang') === 'ar' ? row?.companyNameAr : row?.companyName }</span>,
        sortable: false,
        },
        {
        id: 'payoutMethod',
        name: `${t('deliveryContractors.columnsName.payoutMethod')}`,
        cell: row => <span data-tag="allowRowEvents" className="capitalize">{row?.payoutAmountType?.toLowerCase()?.replace(/_/g,' ')}</span>,
        sortable: false,
        },
        {
        id: 'joiningDate',
        name: `${localStorage.getItem('lang') === 'ar' ? 'تاريخ التسجيل' : 'Joining Date'}`,
        cell: row => dateOnlyFormate(row.createdAt),
        sortable: false,
        },
        {
        id: 'workingCity',
        name: `${localStorage.getItem('lang') === 'ar' ? 'مدينة العمل' : 'Working City'}`,
        selector: row => localStorage.getItem('lang') === 'ar' ? row.workingCityNameAr ? row.workingCityNameAr : t('common.none')  : row?.workingCityName ? row?.workingCityName : t('common.none'),
        sortable: false,
        },
        {
        id: 'workingRegion',
        name: `${localStorage.getItem('lang') === 'ar' ? 'منطقة العمل' : 'Working Region'}`,
        selector: row => localStorage.getItem('lang') === 'ar' ? row.workingRegionNameAr ? row.workingRegionNameAr : t('common.none') : row?.workingRegionName ? row?.workingRegionName  : t('common.none'),
        sortable: false,
        },
        {
        id: 'averageRating',
        name: `${localStorage.getItem('lang') === 'ar' ? 'متوسط التقييم' : 'Average Rating'}`,
        selector: row => <div className='flex' data-tag="allowRowEvents"> {row?.averageRating === null ? [...Array(row?.averageRating)].map( (_,index)=> <AiFillStar key={index} className='text-mainYellow' /> ) : t('common.notRated')} </div>,
        sortable: true,
        },
        {
        id: 'status',
        name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'Status'}`,
        selector: row => <div data-tag="allowRowEvents" className={` capitalize px-2 py-1 rounded-md bg-opacity-10 min-w-[100px] text-center font-semibold ${row?.isActive ? 'text-mainGreen bg-mainGreen ' : 'text-mainRed bg-mainRed'}`}>{row?.isActive === true ? t('common.active') : t('common.deactivated')}</div>,
        sortable: true,
        },
        {
        id: 'actions',
        name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
        allowOverflow: true,
        button : true,
        cell: row=>(
            <>
                {<ActionCell data={row} t={t} />}
            </>
        )
        },
    ];
    
    const data = couriers?.couriers?.data
    
    // console.log(data);
    
    const handleSearch = ()=>{
        dispatch(getCouriers({searchValue}))
    }

    const handleReset = ()=>{
        dispatch(getCouriers({page,size}))
        setsearchValue('')
    }

    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])

    const {handleRemoteSort, icon} = useRemoteSort(getCouriers,dispatch,page,size,searchValue,orderType,null,null,null,payoutStatus)

    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['name', 'email', 'phone', 'courierType', 'payoutMethod', 'status', 'actions']);
    
    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = ()=>{
        setopen(true)
    }
    const handleClose = ()=>{
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['name', 'email', 'phone', 'courierType', 'payoutMethod', 'status', 'actions']);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);


    // filter menu

    const handleTypeChange = (event) => {
        let courierType = event.target.value 
        // console.log(event.target);
        // settype({id:val});
        setorderType(courierType);

        // dispatch(getCouriers({page,size,courierType,searchValue}))
    };


    const handlePayoutStatus = (event) => {
        let courierType = event.target.value 
        // console.log(event.target);
        // settype({id:val});
        setpayoutStatus(courierType);

        // dispatch(getCouriers({page,size,courierType,searchValue}))
    };
    
    return ( 
        <>
            <section className={`relative couriers-control-wrapper flex flex-wrap justify-between items-center mt-5 w-full ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>

                <div className="capitalize flex gap-3 items-center">
                    <FormControl className="capitalize" sx={{ minWidth: 135,border:0,m:0,borderRadius:'6px',backgroundColor:'white','& fieldset': { borderColor: 'primary.main',border:'none' } }} size="small">
                        {orderType ? null : <InputLabel shrink={false} id="select-courier-type" className='text-[#333] font-semibold font-playfair capitalize text-sm'>{t('couriers.courier_details.courierType')}</InputLabel>}
                        <Select
                            labelId="select-courier-type"
                            id="demo-order-select-type"
                            value={orderType}
                            // label="courier type"
                            onChange={handleTypeChange}
                            displayEmpty
                        >
                            <MenuItem value= "all" className='text-[#333] font-semibold font-playfair' placeholder='All'>All</MenuItem>
                            <MenuItem value= "freelancer" className='text-[#333] font-semibold font-playfair' placeholder='Freelancer'>Freelancer</MenuItem>
                            <MenuItem value= "delivery_contractor_courier" className='text-[#333] font-semibold font-playfair' placeholder='work for company'>work for company</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl className="capitalize" sx={{ minWidth: 135,border:0,m:0,borderRadius:'6px',backgroundColor:'white','& fieldset': { borderColor: 'primary.main',border:'none' } }} size="small">
                        {payoutStatus ? null : <InputLabel shrink={false} id="select-type" className='text-[#333] font-semibold font-playfair capitalize text-sm'>{t('couriers.payoutStatus')}</InputLabel>}
                        <Select
                            labelId="select-type"
                            id="payout-status-select-type"
                            value={payoutStatus}
                            // label="payout status"
                            onChange={handlePayoutStatus}
                            displayEmpty
                        >
                            <MenuItem value= " " className='text-[#333] font-semibold font-playfair' placeholder='All'>All</MenuItem>
                            <MenuItem value= "inherited" className='text-[#333] font-semibold font-playfair' placeholder='default'>Default</MenuItem>
                            <MenuItem value= "not_inherited" className='text-[#333] font-semibold font-playfair' placeholder='changed'>changed</MenuItem>
                        </Select>
                    </FormControl>

                </div>

                <div className={`flex gap-x-3 items-center ${localStorage.getItem('lang') === 'ar' ? 'flex-row' : 'flex-row-reverse'}`}>
                    <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                    <FormControl 
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '6px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            placeholder={localStorage.getItem('lang') === 'ar' ? 'ابحث في السائقين...' : 'Search Couriers...'}
                            onChange={(e)=>{
                                setsearchValue(e.target.value.trim())
                                if (!e.target.value) {
                                    handleReset();
                                }}}
                            startAdornment={
                            <InputAdornment position="start">
                                
                                <IconButton
                                    aria-label="search"
                                    onClick={handleSearch}
                                    edge="start"
                                >
                                    <CiSearch />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                            ))}
                            </Box>
                        )}
                        >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white relative">
                {data?.length ===0 ? 
                    <div className='p-8'><NoDataEmoji /></div>
                :

                <>
                    <DataTable
                        direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                        columns={visibleColumns}
                        data={data}
                        pagination
                        paginationPerPage = {size}
                        paginationRowsPerPageOptions = {[10,50,100]}
                        paginationServer
                        paginationTotalRows={couriers?.couriers?.meta?.total}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowChange}
                        customStyles={TableStyles}
                        highlightOnHover
                        onRowClicked={(data)=>{
                            navigate('details',{state:{id:data?.driverId,type:'status',data:data}})
                            dispatch(clearCourierState())
                        }}
                        sortServer
                        onSort={handleRemoteSort}
                        sortIcon={icon}
                        paginationDefaultPage={page}
                        keyField="id"
                        paginationResetDefaultPage = {true}
                        paginationComponentOptions={
                                {
                                    rowsPerPageText : t('common.paginationRowText'),
                                    rangeSeparatorText : t('common.of')
                                }
                            }
                    />
                </>
                }

                {couriers?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}
            </section>
        </>
    );
}

export default AllCouries;